import React from 'react'

export default ({pipe, brkId, handleChange, checked}) => (
    <tr>
        <td style={{width: '1px', verticalAlign: 'middle'}}>
            <input
                type="radio"
                name={`breaks[${brkId}]`}
                value={pipe.id}
                checked={checked}
                onChange={() => handleChange(brkId, pipe.id)}
            />
        </td>
        <td>{pipe.id}</td>
        <td>{pipe.pipe_ref}</td>
        <td>{pipe.installed_on}</td>
        <td>{pipe.yoaq}</td>
        <td>{pipe.material_ref}</td>
        <td>{pipe.length}</td>
        <td>{pipe.diameter}</td>
        <td>{pipe.life_status}</td>
        <td>{pipe.abandoned_on}</td>
        <td>{pipe.location_ref}</td>
    </tr>
);