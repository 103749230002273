import React, { useState, useEffect } from 'react'
import PredictionsTable from '../MLPredictionsTable'
import { CSVLink } from "react-csv"
import MLStatus from '../MLStatus'

export default function MLCalibration(props) {
  const projectId = Api.getProjectId()

  const [results, setResults] = useState({})
  const [pipes, setPipes] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [selectedCohort, setSelectedCohort] = useState("")
  const [rankByMethod, setRankByMethod] = useState("PBN")
  const [includeAbandoned, setIncludeAbandoned] = useState(false)
  const [cohorts, setCohorts] = useState([])
  const [resultsExist, setResultsExist] = useState(false)

  // TODO: everything else should wait for this to check out first
  useEffect(() => {
    Api.get(`projects/${projectId}/ml/meta`)
      .then(x => {
        setResultsExist(true)
      })
      .catch(error => {
        console.log(error)
        if (!error.notFound) {
          setResultsExist(true)  
        }
      })
  }, [])

  useEffect(() => {
    setIsLoading(true)
    Api.get(`projects/${projectId}/cohorts`)
      .then(x => {
        setCohorts(x)
      })
      .catch(error => {
        // toastr.error("Could not fetch cohorts!")
        console.error(error)
      })
  }, [])

  useEffect(() => {
    setIsLoading(true)
    setResults({})
    const query = {}
    if (selectedCohort != "") {
      query["cohort"] = selectedCohort
    }
    query["rank_by"] = rankByMethod
    query["include_abandoned"] = includeAbandoned
    Api.get(`projects/${projectId}/ml/results`, query)
      .then(x => {
        setResults(x)
        setIsLoading(false)
      })
      .catch(error => {
        // toastr.error("Could not fetch validation results!")
        console.error(error)
        setResults({})
        setIsLoading(false)
      })
  }, [selectedCohort, rankByMethod, includeAbandoned])

  useEffect(() => {
    setPipes([])
    const query = { type: "validation" }
    if (selectedCohort != "") {
      query["cohort"] = selectedCohort
    }
    query["include_abandoned"] = includeAbandoned
    Api.get(`projects/${projectId}/ml/pipes`, query)
      .then(x => setPipes(x))
      .catch(error => {
        setPipes([])
        // toastr.error("Could not fetch pipes!")
        console.error(error)
      })
  }, [selectedCohort, includeAbandoned])

  if (cohorts.length === 0) {
    return <div>Fetching cohorts...</div>
  }

  return (
    <>
      <MLStatus disabled={isLoading} />
      { !isLoading && !resultsExist && <p>No machine learning pipeline runs were found for this project. Please run the pipeline and wait for results.</p>}
      { resultsExist && 
      <div className="mt-5">        
        <>
          <>
            <div className="row">
                <select
                  onChange={ (e) => setSelectedCohort(e.currentTarget.value) }
                  disabled={ isLoading }
                  className="custom-select prediction-title-select"
                >
                  <option default value="">All Pipes</option>
                  { cohorts.map(cohort => (
                    <option
                      key={ cohort.id }
                      value={ cohort.id }
                    >
                      { cohort.name }
                    </option>
                  )) }
                </select>
                <select
                  onChange={ (e) => setRankByMethod(e.currentTarget.value) }
                  disabled={ isLoading }
                  className="custom-select prediction-title-select"
                >
                  <option default value="PBN">Ranked by PBN</option>
                  <option default value="PBR">Ranked by PBR</option>
                </select>
            </div>
            <div className="row">
                <div className="form-check">
                  <input
                      value={ includeAbandoned }
                      onChange={ (e) => setIncludeAbandoned(!includeAbandoned) }
                      id="include_abandoned"
                      type="checkbox"
                      className="form-check-input"
                  />
                  <label htmlFor="include_abandoned" className="form-check-label">Include abandoned pipes</label>
                </div>
            </div>
          </>
          { !results.Single && isLoading && <div style={{marginTop: "1em"}}>Fetching validation results...</div>}
          { !results.Single && !isLoading && <div style={{marginTop: "1em"}}>No results!</div>}
          { results.Single && <>
            <div className="row">
              These results were obtained from a model trained on {results["timestamp"]?.split(".")[0]}.
            </div>
            <div className="row">
              <p style={{ width: "100%" }}>
                The model was fitted with {results.Single.windowStart} to {results.Single.splitDate} break data and validated with {results.Single.splitDate} to {results.Single.windowEnd} break data.
              </p>
              <p style={{ textAlign: "center", fontSize: "1.5em", width: "100%" }}>
                <strong>Fitting period: {results.Single.windowStart}-{results.Single.splitDate}</strong>
                <br />
                <strong>Validation period: {results.Single.splitDate}-{results.Single.windowEnd}</strong>
              </p>
              <p style={{ width: "100%" }}>
                A Likelihood of Failure score and Predicted Break Number (PBN) for each pipe for the validation period.
                The sum of the PBN of all the pipes is compared to the actual number of {results.Single.splitDate} to {results.Single.windowEnd} breaks.
              </p>
              <p style={{ textAlign: "center", fontSize: "1.5em", width: "100%" }}>
                <strong>Actual number of breaks: {results.ABN?.toFixed(0)}</strong>
                <br />
                <strong>Predicted number of breaks: {results.Single.PBN?.toFixed(0)}</strong>
                <br />
                <strong>Ratio between Predicted and Actual Breaks: {(results.Single.PBN / results.ABN)?.toFixed(2)}</strong>
                <br />
                <span style={{ fontSize: "1.0rem", fontStyle: "italic" }}>Validation is considered to be better if the predicted/actual ratio is closer to 1.</span>
              </p>
            </div>
            <div className="row">
              <p style={{ width: "100%" }}>
                The pipes are ranked by worse Likelihood of Failure first.
                The graphs below show the % of breaks avoided by replacing a certain % of pipes (number of pipes to the left; length of pipes to the right).
              </p>
            </div>
            <div className="row" style={{"marginTop": "3rem"}}>
              <div className="col-6">
                <strong style={{"marginLeft": "70px"}}>Breaks Avoided per % of Pipe Replacement</strong>
                <img src={"data:image/png;base64, " + results.Single["Breaks_Avoided_Per_Replacement_Number_Img"]}/>
              </div>
              <div className="col-6">
                <strong style={{"marginLeft": "70px"}}>Breaks Avoided per % of Mileage Replacement</strong>
                <img src={"data:image/png;base64, " + results.Single["Breaks_Avoided_Per_Replacement_Length_Img"]}/>
              </div>
            </div>

            <div className="row">
              <p style={{ textAlign: "center", width: "100%", fontStyle: "italic" }}>
                Validation is considered to be better if the blue curve (actual breaks) has a steep increase.
              </p>
              <p>
                Results for 1%, 2%, 5% and 10% of the pipes are compiled in the Table below.
              </p>
            </div>
            <div className="row">
              <table>
                <thead>
                <tr>
                  <th>% of pipes replaced</th>
                  <th>% of breaks avoided (pipe % replacement)</th>
                  <th>% of breaks avoided (length % replacement)</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                  <td>1%</td>
                  <td>{results.Single["pipe_number_1pc_breakpoint"]?.toFixed(2)}%</td>
                  <td>{results.Single["pipe_length_1pc_breakpoint"]?.toFixed(2)}%</td>
                </tr>
                <tr>
                  <td>2%</td>
                  <td>{results.Single["pipe_number_2pc_breakpoint"]?.toFixed(2)}%</td>
                  <td>{results.Single["pipe_length_2pc_breakpoint"]?.toFixed(2)}%</td>
                </tr>
                <tr>
                  <td>5%</td>
                  <td>{results.Single["pipe_number_5pc_breakpoint"]?.toFixed(2)}%</td>
                  <td>{results.Single["pipe_length_5pc_breakpoint"]?.toFixed(2)}%</td>
                </tr>
                <tr>
                  <td>10%</td>
                  <td>{results.Single["pipe_number_10pc_breakpoint"]?.toFixed(2)}%</td>
                  <td>{results.Single["pipe_length_10pc_breakpoint"]?.toFixed(2)}%</td>
                </tr>
                </tbody>
              </table>
            </div>
            <div className="row">
              <p style={{ textAlign: "center", fontSize: "1.5em", width: "100%" }}>
                <strong>{results.Single["pipe_number_10pc_breakpoint"]?.toFixed(2)}% ({results.Single["pipe_length_10pc_breakpoint"]?.toFixed(2)}%)
                of the actual {results.Single.splitDate}-{results.Single.windowEnd} breaks would have been avoided if, by {results.Single.splitDate}, 10% of the number
                (length) of pipes had been replaced.</strong>
              </p>
            </div>
            <div className="row">
              <p style={{ width: "100%" }}>
                The C-index is another measure of how well the model ranks the pipes by their Likelihood of Failure.
              </p>
              <p style={{ textAlign: "center", fontSize: "1.5em", width: "100%" }}>
                <strong>The C-index is {results.Single["C-Index"]?.toFixed(2)}</strong>
                <br />
                <span style={{ fontSize: "1.0rem", fontStyle: "italic" }}>C-index &gt; 0.8: model is predictive. C-index &lt; 0.5: model is not very predictive.</span>
              </p>
            </div>
            <div className="row">
              {pipes.length > 0 && <p style={{ width: "100%" }}>
                The Table below shows the ranking of the pipes with the validation model.
              </p>}
            </div>
          </>}
          {results.Single && pipes.length > 0 && <>
            <div className="row">
              <CSVLink
                data={pipes}
                headers={["validation_rank_pbn", "validation_rank_pbr", "utility_pipe_id", "length", "diameter", "material", "installation_date", "validation_pbn", "validation_breaks", "calibration_breaks"]}
                target="_blank"
                className="btn btn-secondary btn-sm"
                filename={"export.csv"}
              >
                Export to CSV
              </CSVLink>
            </div>
            <div className="row">
              <PredictionsTable data={pipes} validation={true} />
            </div>
            
          </>}
        </>     
      </div>
      }
    </>
  )
}