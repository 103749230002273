import React from 'react'
import ReactDOM from 'react-dom'
import { map, difference } from 'lodash'

import getChangedKeys from '../utils/changedKeys'

import Table from './FullscreenTable/Table'
import SearchInput from './FullscreenTable/SearchInput'
import QueryBuilderInput from './FullscreenTable/QueryBuilderInput'

import { stringify as stringifyQuery, parse as parseQuery } from '../utils/querystring'

export default class FullscreenTable extends React.PureComponent {
  state = {
    query: parseQuery(window.location.href.split('?')[1]),
    recordsCount: undefined
  }

  setQuery = (toUpdate) => this.setState(({ query }) => ({
    query: {
      ...query,
      ...toUpdate
    }
  }))

  resetRecordsCount = () => this.setState({ recordsCount: undefined })
  onScopeChange = (e) => {
    const scope = e.currentTarget.value

    this.setQuery({ scope })
  }
  onInitialChange = (e) => this.setQuery({ initial: e.currentTarget.value })
  onLifeStatusChange = (e) => this.setQuery({ life_status: e.currentTarget.value })
  onSearchChange = (search) => this.setQuery({ search })
  onPredicatesChange = (predicates) => this.setQuery({ predicates })
  onOrderChange = (sort, dir) => this.setQuery({ sort, dir })

  onDataRequestComplete = (data) => {
    this.setState({ recordsCount: data.responseJSON.formatedRecordsFiltered })
  }

  renderHeader() {
    if (!this._headerNode) {
      this._headerNode = document.getElementById(this.props.headerId)
    }

    const { query, recordsCount } = this.state
    const { mapUrl, csvUrl, xlsxUrl } = this.props

    const queryString = stringifyQuery(query, { arrayFormat: 'brackets' })

    return ReactDOM.createPortal(
      <React.Fragment>
        <h2>
          { this.props.title
          } <span className="datatable-fullscreen-recordsCount">{
            recordsCount !== undefined ? ` (${recordsCount})` : (
              <span className="icon spinner spin datatable-fullscreen-loading" />
            )
          }</span>
        </h2>
        <div className="datatable-fullscreen-filters">
          <select
            value={ query.scope }
            onChange={ this.onScopeChange }
            className="custom-select"
            style={{ fontSize: '18px' }}
          >{
            map(this.props.scopes, ([name, value]) => (
              <option
                key={ value }
                value={ value }
              >{ name }</option>
            ))
          }</select>
          <select
            value={ query.initial }
            onChange={ this.onInitialChange }
            className="custom-select"
            style={{ fontSize: '18px', width: '8em' }}
          >
            <option value="">Current</option>
            <option value="1">Initial</option>
          </select>
          <select
            value={ query.life_status }
            onChange={ this.onLifeStatusChange }
            className="custom-select"
            style={{ fontSize: '18px', width: '12em' }}
          >
            <option value="">Active & Abandoned</option>
            <option value="active">Active</option>
            <option value="abandoned">Abandoned</option>
          </select>
          <QueryBuilderInput value={ query.predicates } onChange={ this.onPredicatesChange } />
          <SearchInput value={ query.search } onChange={ this.onSearchChange } />
        </div>
        <div className="top-action">
          { mapUrl && (
            <a href={ `${mapUrl}?${queryString}` } className="with-icon" title="View on map">
              <i className="fa-solid fa-map" style={{fontSize: "1.6rem"}}/>
              <span>View on Map</span>
            </a>
          )}

          { csvUrl && (
            <a href={ `${csvUrl}?${queryString}` } className="with-icon" title="Download CSV file">
              <i className="fa-solid fa-file-csv" style={{fontSize: "1.6rem"}}/>
              <span>Download CSV file</span>
              </a>
          )}

          { xlsxUrl && (
            <a href={ `${xlsxUrl}?${queryString}` } className="with-icon" title="Download XLSX file">
              <i className="fa-solid fa-file-excel" style={{fontSize: "1.6rem"}}/>
              <span>Download XLSX file</span>
            </a>
          )}
        </div>
      </React.Fragment>,
      this._headerNode
    )
  }

  componentDidMount() {
    const query = this.state.query

    if (this.props.onQueryChanged) {
      this.props.onQueryChanged(query)
    }
  }

  componentDidUpdate(_prevProps, prevState) {
    if (this.state.query !== prevState.query) {
      const changedKeys = getChangedKeys(prevState.query, this.state.query)

      if (window.history.replaceState) {
        const query = this.state.query

        const base = window.location.href.replace(/\?(.+)?$/, '')
        const queryString = stringifyQuery(query, { arrayFormat: 'brackets' })

        window.history.replaceState({}, window.title, `${base}?${queryString}`)

        if (this.props.onQueryChanged) {
          this.props.onQueryChanged(query)
        }
      }

      // if query has changed, and it's not just order
      if (difference(changedKeys, ['sort', 'dir']).length > 0) {
        this.resetRecordsCount()
      }
    }
  }

  render() {
    const {
      customFields, columns, dataUrl, defaultOrder
    } = this.props
    const { query } = this.state

    return (
      <React.Fragment>
        { this.renderHeader() }
        <Table
          query={ query }
          defaultOrder={ defaultOrder }
          customFields={ customFields }
          columns={ columns }
          dataUrl={ dataUrl }
          onOrderChange={ this.onOrderChange }
          onDataRequestComplete={ this.onDataRequestComplete }
        />
      </React.Fragment>
    )
  }
}

