import React from 'react'
import { map, transform, sortBy } from 'lodash'
import memoize from 'lru-memoize'
import qs from 'qs'

import PlanChart from './PlanChart'
import chroma from "chroma-js"

import Scenarios from './Scenarios'

const { $ } = window

const INITIAL_SCENARIO_INDEX = 0

const getQuery = memoize(2)((params) => {
  if (!params) return ''

  return qs.stringify({
    ...params,
    limitations: JSON.stringify(params.limitations)
  }, { arrayFormat: 'brackets' })
})

const isScenarioChanged = memoize()((scenario, lastQuery) => getQuery(scenario) !== lastQuery)

const cohortsDataCache = {}

const getCohortDataUrl = (baseUrl, params) => `${baseUrl}?${getQuery(params)}`
const getCohortData = (loadDataUrl, params) => {
  const url = getCohortDataUrl(loadDataUrl, params)

  // Disabling cache because it's doing more harm than good at this stage
  // if (!cohortsDataCache[url]) {
  cohortsDataCache[url] = $.getJSON(url).then(
    null,
    (error) => {
      cohortsDataCache[url] = undefined

      return error
    }
  )
  //}

  return cohortsDataCache[url]
}

const postScenarios = (url, data) => (
  $.post(url, { scenarios: data }, null, 'json')
)

// TODO: hack to go away with Ruby
const deleteScenario = (url, id) => (
  $.ajax({url: url.split("/").slice(0, -1).join("/") + "/" + id, type: 'DELETE'})
)

const sortCohorts = memoize()((cohorts) => sortBy(cohorts, 'name'))

const getColorsMap = memoize()((available_cohorts) => {
  let paletteIndex = 0
  const paletteIncrement = 1 / available_cohorts.length
  const colorScale = chroma.scale('RdYlBu')

  return transform(
    available_cohorts,
    (result, { name, color: _color }) => {
      let color = _color

      if (!color) {
        color = colorScale(paletteIndex).hex()
        paletteIndex += paletteIncrement
      }

      if(name == "I") {
        result[name] = "#ffc107"
      } else if(name == "D") {
        result[name] = "#c00"
      } else {
        result[name] = color // eslint-disable-line no-param-reassign
      } 
    },
    {}
  )
})

export default class Plan extends React.PureComponent {
  limitationsInput = React.createRef()

  blankScenario = {
    mabr: 0.15,
    horizon: this.props.default_horizon,
    randomness: 0.0,
    limitations: [],
    cohort_ids: map(this.props.available_cohorts, 'id'),
    replacement: "false",
    selection: "LOF",
    active_constraints: 'length',
    max_length_first_year: 10,
    year_steady_state: 2040
  }

  state = {
    loading: false,
    data: undefined,
    error: false,
    currentScenario: this.props.scenarios[INITIAL_SCENARIO_INDEX],
    loadedScenarioQuery: getQuery(this.props.scenarios[INITIAL_SCENARIO_INDEX]),
    lessReplacement: false
  }

  onScenarioChange = (scenario) => this.setState({ currentScenario: scenario })
  onScenarioSelect = (scenario) => this.setState({ currentScenario: scenario })

  onScenarioDelete = (id) => deleteScenario(this.props.save_scenarios_url, id)

  componentDidMount() {
    // this.loadData()
  }

  componentWillUnmount() {
    const { pollingId } = this.state
    if (pollingId) {
      clearInterval(pollingId)
    }
  }

  onSubmit = (e) => {
    e.preventDefault()
    this.apply()
  }

  loadData = () => {
    const { load_data_url } = this.props
    const { currentScenario: scenario } = this.state

    const { pollingId } = this.state
    if (pollingId) {
      clearInterval(pollingId)
    }

    this.setState({
      lessReplacement: false,
      loading: true,
      error: false,
      loadedScenarioQuery: scenario && getQuery(scenario)
    })

    getCohortData(load_data_url, scenario).then(
      this.polling,
      this.onDataLoadFailed
    )
  }

  polling = (resp) => {
    const projectId = Api.getProjectId()
    const { plan_id } = resp
    console.log(resp)

    const pollingId = setInterval(() => {
      console.log("Polling plan...")
      Api.get(`projects/${projectId}/ml/plan`, { planId: plan_id })
      .then(x => {
        const { pollingId } = this.state
        clearInterval(pollingId)
        this.onDataLoaded(x)
      })
      .catch(error => {
        if (error.error && error.error != "HTTP error: 404") {
          console.log(error)
          const { pollingId } = this.state
          clearInterval(pollingId)
          this.onDataLoadFailed()
        } else {
          console.log("No results yet.")
        }
      })
    }, 5000)

    this.setState({ pollingId })
  }

  apply = () => {
    this.loadData()
  }

  saveScenarios = (scenarios) => {
    const { save_scenarios_url } = this.props

    return postScenarios(save_scenarios_url, scenarios)
  }

  onDataLoaded = (data) => {
    data = data.data || data;
    const { currentScenario } = this.state

    if (currentScenario &&
        currentScenario.active_constraints === "break_rate" &&
        data.series.length > 4 &&
        parseFloat(currentScenario.max_length_first_year) > Object.values(data.series[5].length_to_replace_per_cohort).reduce((a, b) => a + b) + 0.01) {

        this.setState({ lessReplacement: true })
    } else {
        this.setState({ lessReplacement: false })
    }

    this.setState({ data, loading: false })
  }
  onDataLoadFailed = (xhr) => {
    let error

    try {
      error = JSON.parse(xhr.responseText).error
    } catch { } // eslint-disable-line no-empty

    this.setState({ data: undefined, loading: false, error: true })
  }

  render() {
    const {
      available_cohorts,
      download_data_url
    } = this.props
    const {
      loading,
      data,
      currentScenario,
      loadedScenarioQuery,
      error,
      lessReplacement
    } = this.state

    const scenarioChanged = isScenarioChanged(currentScenario, loadedScenarioQuery)
    const colors = getColorsMap(available_cohorts)
    const sortedCohorts = sortCohorts(available_cohorts)

    const downloadUrl = `${download_data_url}?${loadedScenarioQuery}`

    return (
      <div className="plan-container">
        <Scenarios
          scenarios={ this.props.scenarios }
          initialIndex={ INITIAL_SCENARIO_INDEX }
          currentScenario={ currentScenario }
          cohorts={ sortedCohorts }
          colors={ colors }
          onScenarioChange={ this.onScenarioChange }
          onScenarioSelect={ this.onScenarioSelect }
          onScenarioDelete={ this.onScenarioDelete }
          blankScenario={ this.blankScenario }
          saveScenarios={ this.saveScenarios }
          getQuery={ getQuery }
        />
        { error && (
          <div style={{ position: 'relative' }}>
            <div className="plan-outdated">
              <div className="plan-outdated-message" style={{ marginTop: '2em' }}>Something is wrong with this plan.</div>
              <button
                type="button"
                className="btn btn-primary plan-outdated-button infraplan_button_small"
                onClick={ this.apply }
              >Retry</button>
            </div>
          </div>
        )}
        {
          !error && !data && !loading && <div style={{ position: 'relative' }}>
          <div className="plan-outdated">
            <div className="plan-outdated-message" style={{ marginTop: '2em' }}>Select a scenario and click run:</div>
            <button
              type="button"
              className="btn btn-primary plan-outdated-button infraplan_button_small"
              onClick={ this.apply }
            >Run</button>
          </div>
        </div>
        }
        {
          lessReplacement && <div className="alert alert-info" style={{ textAlign: "center", fontSize: "1.2em" }}>Objectives met with less replacement!</div>
        }
        { data && (
          <div className="plan-charts">
            <div className="mb-4">
              <PlanChart
                data={ data }
                colors={ colors }
                available_cohorts = { available_cohorts }
                downloadUrl={ downloadUrl }
                measures={["do_nothing_br", "br"]}
                title={"Future Break Rate and Length of Rehabilitation & Replacement by Year" }
              />

              <PlanChart
                  data={ data }
                  colors={ colors }
                  available_cohorts = { available_cohorts }
                  downloadUrl={ downloadUrl }
                  measures={["do_nothing_bre", "bre"]}
                  linesLabel={"BRE"}
                  title={"Future Business Risk Exposure and Length of Rehabilitation & Replacement by Year" }
              />

              <PlanChart
                  data={ data }
                  colors={ colors }
                  available_cohorts = { available_cohorts }
                  downloadUrl={ downloadUrl }
                  measures={["cost"]}
                  linesLabel={"Cost ($M)"}
                  title={"Future Cost and Length of Rehabilitation & Replacement by Year" }
              />

              <PlanChart
                  data={ data }
                  colors={ colors }
                  available_cohorts = { available_cohorts }
                  downloadUrl={ downloadUrl }
                  measures={["replacement_rate"]}
                  linesLabel={"Replacement Rate (%)"}
                  title={"Future Rate of Rehabilitation & Replacement by Year (not length)" }
              />
            </div>
            { scenarioChanged && (
              <div className="plan-outdated">
                <div className="plan-outdated-message">The scenario has changed, click apply to refresh the charts</div>
                <button
                  type="button"
                  className="btn btn-primary plan-outdated-button infraplan_button_small"
                  onClick={ this.apply }
                >Apply</button>
              </div>
            ) }
          </div>
        ) }
        { loading && (
          <div className="submitter-overlay">
            <i className="icon big spinner spin" />
          </div>
        ) }
      </div>
    )
  }
}
