import React, { useState, useEffect } from 'react'
import styled from 'styled-components'

const TableHeader = styled.th`
  font-size: 12px;
  font-weight: bold;
  color: black;
  text-align: ${props => props.first ? "left" : "center"};
  border-top: none;
  border-bottom: none;
  border-left-style: ${props => props.first ? "none" : "solid"};
  border-right-style: ${props => props.last ? "none" : "solid"};
`

const TableSubHeader = styled.th`
  border-top: none;
  text-align: center;
  font-size: 12px;
  color: black;
  border-left-style: ${props => props.first ? "none" : "solid"};
  border-right-style: ${props => props.last ? "none" : "solid"};
`

const TableRow = styled.tr`
  &&& {
    background-color: white;
    border-top: ${props => props.subheader ? "solid" : ""};
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  }
  &&&:hover {
    background-color: #d3dfe9;
  }
`

const TableCell = styled.td`
  font-size: 14px;
  text-align: right;
  border-left-style: ${props => props.first ? "none" : "solid"};
  border-right-style: ${props => props.last ? "none" : "solid"};
  color: ${props => props.current ? "blue" : "black"};
`

export default function MLCohorts() {
  const [isLoading, setIsLoading] = useState(true)
  const [cohorts, setCohorts] = useState(undefined)

  const projectId = Api.getProjectId()

  useEffect(() => {
    Api.get(`projects/${projectId}/ml/cohorts`)
      .then(x => {
        setCohorts(x)
        setIsLoading(false)
        console.log('cohorts', x)
      })
      .catch(x => {
        setIsLoading(false)
        console.log('error', x)
      })
  }, [])

  if (isLoading) {
    return <div>Loading...</div>
  }

  if (!cohorts) {
    return <div>No machine learning pipeline runs were found for this project. Please run the pipeline and wait for results.</div>
  }

  // TODO: connect this to actual cohort creation
  const urls = {}

  // <a
  //   href={ urls.new_cohort_url }
  //   className="btn btn-primary btn-sm"
  // >Create cohort</a>

  return (
    <div className="cohorts">
      <div className="mb-5">


        <div className="btn-group float-right">
          <a className="btn btn-sm btn-secondary">Download</a>

          <button type="button" className="btn btn-sm btn-secondary dropdown-toggle dropdown-toggle-split" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <span className="sr-only">Toggle Dropdown</span>
          </button>

          <div className="dropdown-menu dropdown-menu-right">
            <a className="dropdown-item">Excel</a>
            <a className="dropdown-item">CSV</a>
          </div>
        </div>
      </div>

      <div className="cohorts-body">
        <table>
          <thead>
            <TableRow>
              <TableHeader first rowSpan="2">Name</TableHeader>
              <TableHeader colSpan="6">Length (mi)</TableHeader>
              <TableHeader rowSpan="2" colSpan="2">Rate of ABN/ALL</TableHeader>
              <TableHeader colSpan="6">Average Yearly Break Rate <sup>(1)</sup><sup>(2)</sup></TableHeader>
              <TableHeader colSpan="2" rowSpan="2">Ratio of Average Yearly Break Rate ABN/ALL</TableHeader>
              <TableHeader rowSpan="2" colSpan="2">Average Break Rate {cohorts[0].ml?.validationStart ? cohorts[0].ml?.validationStart + "-" + cohorts[0].ml?.validationEnd : "Final 2 Years"}<sup>(1)</sup></TableHeader>
              <TableHeader colSpan="6">Average Age <sup>(2)</sup></TableHeader>
              <TableHeader rowSpan="2">Predicted Break Rate</TableHeader>
              <TableHeader rowSpan="2">Aging Factor <sup>(3)</sup></TableHeader>
              <TableHeader last colSpan="3">Effective Use Life (years)</TableHeader>
            </TableRow>
            <TableRow>
              <TableSubHeader colSpan="2">
                ALL
              </TableSubHeader>
              <TableSubHeader colSpan="2">
                ACT
              </TableSubHeader>
              <TableSubHeader colSpan="2">
                ABN
              </TableSubHeader>
              <TableSubHeader colSpan="2">
                ALL
              </TableSubHeader>
              <TableSubHeader colSpan="2">
                ACT
              </TableSubHeader>
              <TableSubHeader colSpan="2">
                ABN
              </TableSubHeader>
              <TableSubHeader colSpan="2">
                ALL
              </TableSubHeader>
              <TableSubHeader colSpan="2">
                ACT
              </TableSubHeader>
              <TableSubHeader colSpan="2">
                ABN
              </TableSubHeader>
              <TableSubHeader>
                0.15 <sup>(1)</sup>
              </TableSubHeader>
              <TableSubHeader>
                0.20 <sup>(1)</sup>
              </TableSubHeader>
              <TableSubHeader>
                0.34 <sup>(1)</sup>
              </TableSubHeader>
            </TableRow>
          </thead>
          <tbody>
            {cohorts.sort((a,b) => {
              if (a.name == "Total") return -1
              if (b.name == "Total") return 1
              if (a.name > b.name) return 1
              return -1
            }).map((cohort, i) => {
              return (
                <TableRow subheader={i === 0} key={cohort.name}>
                  <TableCell first>{cohort.name}</TableCell>
                  <TableCell current>{cohort.plat?.length.all.toFixed(1) || "-"}</TableCell>
                  <TableCell>{cohort.ml?.length.all.toFixed(1) || "-"}</TableCell>
                  <TableCell current>{cohort.plat?.length.act?.toFixed(1) || "-"}</TableCell>
                  <TableCell>{cohort.ml?.length.act?.toFixed(1) || "-"}</TableCell>
                  <TableCell current>{cohort.plat?.length.abn?.toFixed(1) || "-"}</TableCell>
                  <TableCell>{cohort.ml?.length.abn?.toFixed(1) || "-"}</TableCell>
                  <TableCell current>{cohort.plat?.abn_all_rate ? (cohort.plat?.abn_all_rate * 100).toFixed(1) + "%" : "-"}</TableCell>
                  <TableCell>{cohort.ml?.abn_all_rate ? (cohort.ml?.abn_all_rate * 100).toFixed(1) + "%" : "-"}</TableCell>
                  <TableCell current>{cohort.plat?.aybr.all?.toFixed(3) || "-"}</TableCell>
                  <TableCell>{cohort.ml?.aybr.all?.toFixed(3) || "-"}</TableCell>
                  <TableCell current>{cohort.plat?.aybr.act?.toFixed(3) || "-"}</TableCell>
                  <TableCell>{cohort.ml?.aybr.act?.toFixed(3) || "-"}</TableCell>
                  <TableCell current>{cohort.plat?.aybr.abn?.toFixed(3) || "-"}</TableCell>
                  <TableCell>{cohort.ml?.aybr.abn?.toFixed(3) || "-"}</TableCell>
                  <TableCell current>{cohort.plat?.abn_all_aybr_rate?.toFixed(1) || "-"}</TableCell>
                  <TableCell>{cohort.ml?.abn_all_aybr_rate?.toFixed(1) || "-"}</TableCell>
                  <TableCell current>{cohort.plat?.abr?.toFixed(3) || "-"}</TableCell>
                  <TableCell>{cohort.ml?.abr?.toFixed(3) || "-"}</TableCell>
                  <TableCell current>{cohort.plat?.average_age.all?.toFixed(1) || "-"}</TableCell>
                  <TableCell>{cohort.ml?.average_age.all?.toFixed(1) || "-"}</TableCell>
                  <TableCell current>{cohort.plat?.average_age.act?.toFixed(1) || "-"}</TableCell>
                  <TableCell>{cohort.ml?.average_age.act?.toFixed(1) || "-"}</TableCell>
                  <TableCell current>{cohort.plat?.average_age.abn?.toFixed(1) || "-"}</TableCell>
                  <TableCell>{cohort.ml?.average_age.abn?.toFixed(1) || "-"}</TableCell>
                  <TableCell>{cohort.ml?.pbr?.toFixed(3) || "-"}</TableCell>
                  <TableCell>{cohort.ml?.aging_factor?.toFixed(3) || "-"}</TableCell>
                  <TableCell>{cohort.ml?.eul015?.toFixed(1) || "-"}</TableCell>
                  <TableCell>{cohort.ml?.eul02?.toFixed(1) || "-"}</TableCell>
                  <TableCell last>{cohort.ml?.eul034?.toFixed(1) || "-"}</TableCell>
                </TableRow>
              )
            })}
          </tbody>
        </table>

        <ol className="cohorts-notes">
          <li>number of breaks / mile / year</li>
          <li>as of now for ACT pipes, as of YOA for ABN pipes</li>
          <li>
            <div className="prediction-curve-formula">
              <i>PBR</i>
              <sub>year&nbsp;n+1</sub>
              <span> = </span>
              <i>PBR</i>
              <sub>year&nbsp;n</sub>
              <span> × </span>
              <strong>Aging Factor</strong>
            </div>
          </li>
          <li>Capped at 200</li>
        </ol>
      </div>
    </div>
  )
}
