import React from 'react'
import {
  map, compact, includes, cloneDeep
} from 'lodash'
import FullscreenTable from './FullscreenTable'
import { stringify as stringifyQuery } from '../utils/querystring'

const { helpers } = window

const COLUMNS = [
  {
    data: 'break.break_ref',
    title: 'Id',
    render(data, type, row) {
      if (type === 'display') {
        return `<a target='_blank' href='breaks/${row.break.id}/edit'>${row.break.break_ref || '(missing)'}</a>`
      }
      return data
    }
  },

  helpers.dt.data({
    data: 'break.updated_at',
    title: 'Last Update',
    small: true,
    type: 'date',
    orderSequence: ['desc', 'asc']
  }),

  {
    data: 'break.latest_log_entry_creator_name',
    title: 'Updated By',
    render(data, type) {
      if (type === 'display') {
        const initials = (data || '')
          .split(',')
          .map((x) => { return (x || '').trim()[0] || '' })
          .filter((x) => { return x })
          .join('')
        return `<span title='${data}'>${initials}</span>`
      }
      return data
    }
  },

  helpers.dt.data({
    data: 'break.issues',
    title: '# Break Issues',
    type: 'list-length',
    showInitial: true,
    orderSequence: ['desc', 'asc']
  }),

  helpers.dt.data({
    data: 'break.issues',
    title: 'Issues',
    type: 'list',
    small: true,
    showInitial: true,
    orderable: false,
    orderSequence: ['desc', 'asc'],
    className: 'datatable-longline'
  }),

  helpers.dt.data({
    data: 'break.broke_on',
    title: 'Break Date',
    type: 'date',
    format: 'YYYY/MM/DD',
    showInitial: true
  }),

  helpers.dt.data({
    data: 'break.break_type',
    title: 'Break Type',
    type: 'text',
    showInitial: true
  }),

  helpers.dt.data({
    data: 'break.approved',
    title: 'Approved',
    type: 'boolean',
    showInitial: true
  }),

  {
    data: 'pipe.editor_status',
    title: 'Pipe - Status',
    defaultContent: '',
    render(data) {
      if (typeof data === 'undefined' || data === null || data === '') {
        return 'No changes'
      }
      return data
    }
  },
  {
    data: 'break.pipe_ref',
    title: 'Pipe ID',
    render(data, type, row) {
      let result = data

      if (row.pipe && row.pipe.id) {
        result = `<a target='_blank' href='pipes/${row.pipe.id}'>${result}</a>`
      }

      if (row.initial.break && row.initial.break.pipe_id !== data) {
        result = `<span style="color: red">${row.initial.break.pipe_id}</span> ; ${result}`
      }

      return result
    },
    showInitial: true
  },

  helpers.dt.data({
    data: 'pipe.issues',
    defaultContent: '',
    title: 'Pipe - # Issues',
    type: 'list-length',
    orderSequence: ['desc', 'asc'],
    showInitial: true
  }),

  helpers.dt.data({
    data: 'pipe.issues',
    defaultContent: '',
    title: 'Pipe - Issues',
    type: 'list',
    small: true,
    orderable: false,
    showInitial: true,
    className: 'datatable-longline'
  }),

  helpers.dt.data({
    data: 'pipe.installed_on',
    defaultContent: '',
    title: 'Pipe - Date of<br>Installation',
    type: 'date',
    format: 'YYYY/MM/DD',
    showInitial: true
  }),

  helpers.dt.data({
    data: 'pipe.yoaq',
    title: 'Pipe - Year of<br>Acquisition',
    defaultContent: '',
    showInitial: true
  }),

  helpers.dt.data({
    data: 'pipe.material_ref',
    defaultContent: '',
    title: 'Pipe - Material',
    showInitial: true
  }),

  helpers.dt.data({
    data: 'pipe.length',
    defaultContent: '',
    title: 'Pipe - Length',
    type: 'two-decimals',
    showInitial: true,
  }),

  helpers.dt.data({
    data: 'pipe.diameter',
    defaultContent: '',
    title: 'Pipe - Diameter',
    type: 'two-decimals',
    showInitial: true,
  }),

  helpers.dt.data({
    data: 'pipe.location_ref',
    defaultContent: '',
    title: 'Pipe - Location',
    showInitial: true,
  }),

  helpers.dt.data({
    data: 'pipe.life_status',
    defaultContent: '',
    title: 'Pipe - Life<br>Status',
    showInitial: true,
  }),

  helpers.dt.data({
    data: 'pipe.abandoned_on',
    defaultContent: '',
    title: 'Pipe - Date of<br>Abandonment',
    type: 'date',
    format: 'YYYY/MM/DD',
    showInitial: true,
  }),

  helpers.dt.data({
    data: 'pipe.cof',
    title: 'COF',
    defaultContent: '',
    showInitial: true
  }),

  helpers.dt.data({
    data: 'pipe.custom_1',
    defaultContent: '',
  }),
  helpers.dt.data({
    data: 'pipe.custom_2',
    defaultContent: '',
  }),
  helpers.dt.data({
    data: 'pipe.custom_3',
    defaultContent: '',
  }),

  helpers.dt.data({
    data: 'pipe.custom_4',
    defaultContent: '',
  }),

  helpers.dt.data({
    data: 'pipe.custom_5',
    defaultContent: '',
  }),

  helpers.dt.data({
    data: 'pipe.custom_6',
    defaultContent: '',
  }),

  helpers.dt.data({
    data: 'pipe.custom_7',
    defaultContent: '',
  }),

  helpers.dt.data({
    data: 'pipe.custom_8',
    defaultContent: '',
  }),

  helpers.dt.data({
    data: 'pipe.comment',
    defaultContent: '',
    title: 'Pipe - Comment'
  }),

  helpers.dt.data({
    data: 'break.comment',
    defaultContent: '',
    title: 'Comment'
  }),
]

export default class BreaksTable extends React.PureComponent {
  constructor(props) {
    super(props)

    const { customFields, allowedOrdering } = this.props

    let columns = COLUMNS

    // add / remove custom fields
    columns = map(columns, (column) => {
      if ((/^pipe\.custom_/).test(column.data)) {
        const key = column.data.split('.')[1]

        if (customFields[key]) {
          return { ...column, title: `Pipe - ${customFields[key]}` }
        }

        return null
      }

      return column
    })

    // remove empty
    columns = compact(columns)

    // add indices
    columns = map(columns, (column, index) => ({
      ...column,
      name: column.name || column.data,
      index,
      showInitial: true,
      orderable: (
        column.orderable !== undefined ? column.orderable : includes(allowedOrdering, column.data)
      )
    }))

    this.columns = columns

    this.state = {
      pipesQuery: ''
    }
  }

  onQueryChanged = (query) => {
    // TODO: for back links from map/edit
    // show be deprecated
    const queryWithStringifiedPredicate = {
      ...query,
      predicates: query.predicates ? JSON.stringify(query.predicates) : undefined
    }
    window.localStorage.setObject('BreakListScope', queryWithStringifiedPredicate)

    const pipesQuery = cloneDeep(query)
    delete pipesQuery.sort
    if (/^(Filter|Issue)_/.test(pipesQuery.scope)) {
      delete pipesQuery.scope
    }

    this.setState({ pipesQuery: stringifyQuery(pipesQuery) })
  }

  render() {
    const { pipesQuery } = this.state
    const { navigation } = this.props

    const title = (
        <h2>Breaks</h2>
    )

    return (
      <FullscreenTable
        { ...this.props }
        columns={ this.columns }
        dataUrl="./breaks.json"
        title={ title }
        mapUrl="./breaks/map"
        csvUrl="./breaks.csv"
        xlsxUrl="./breaks.xlsx"
        onQueryChanged={ this.onQueryChanged }
        defaultOrder={ ['break.updated_at', 'desc'] }
      />
    )
  }
}
