import React, {useEffect, useState} from 'react'
import Section from "./Section";
import BreakTable from "./BreakTable";
import {calculateMostFittingPipe, getAssignedPipe} from "./helpers";

const Description =
    `This module allows for assigning a pipe to a break for the cases where more than 1 pipe can
 be assigned to a break. For each break please select which one seems more adequate: on the top. The pipes will
 appear ordered by their likelihood of being the right one.`;




export default () => {

    const [{isLoading, breaks}, setBreaks] = useState({isLoading: true, breaks: []});
    const [changes, setChanges] = useState({});

    function loadBreaks() {
        if(isLoading) {
            helpers.showProgress(true, {message: ("loading breaks...")});

            Api.get(`projects/${Api.getProjectId()}/breaks/conflicts`)
                .then(res => {
                    console.log(res);
                    setBreaks({breaks: res, isLoading: false});
                })
                .catch(error => {
                    console.log(error)
                    if (!error.notFound) {
                        toastr.error("Could not fetch conflicts in breaks data!");
                        helpers.showProgress(false);
                    }
                    setBreaks({breaks: [], isLoading: false})
                });

        } else {
            helpers.showProgress(false);
        }
    }

    function autoAssignPipesToBreaks(skipAssigned) {

        let newChanges = {};
        for (let [key, value] of Object.entries(changes)) {
            newChanges[key] = value;
        }

        for (let b of breaks) {
            if(skipAssigned) {
                let id = getAssignedPipe(b, changes[b.id]);
                if(typeof id !== "undefined" && id !== null) {
                    continue;
                }
            }

            newChanges[b.id] = calculateMostFittingPipe(b).id;
        }

        setChanges(newChanges);
    }

    function submitChanges() {
        if(Object.keys(changes).length <= 0) {
            toastr.error("No changes were selected!");
        } else {
            helpers.showProgress(true, {message: ("connecting pipes to breaks...")});
            Api.put(`projects/${Api.getProjectId()}/breaks/conflicts`, changes)
                .then(res => {

                    toastr.success("Changes submitted successfully! Please recompute the stats to reflect on current numbers!");
                    setChanges({});
                    setBreaks({
                        breaks: [],
                        isLoading: true
                    });

                })
                .catch(error => {
                    console.log(error)
                    if (!error.notFound) {
                        toastr.error("Could not update connections!");
                        helpers.showProgress(false);
                    }
                    setBreaks({breaks: [], isLoading: false})
                });
        }
    }

    useEffect(() => {
        const handleBeforeUnload = (event) => {
            if (Object.keys(changes).length > 0) {
                // Standard message for most browsers
                const message = 'You have unsaved changes. Are you sure you want to leave?';
                event.returnValue = message; // Standard for most browsers
                return message; // For some older browsers
            }
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        // Cleanup the event listener when the component unmounts or items change
        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, [changes]);

    useEffect(() => {
        loadBreaks();
    }, [isLoading]);

    return (
        <>
            <Section
                title={"Assign Pipes to Breaks"}
                description={Description}
            >
                <p><b>Note: </b> the calculation of the new issues is only done once you recompute the stats!</p>
            </Section>

            {
                !isLoading ?
                    breaks.length > 0 ?
                        <>
                            <p><b>Changes to submit: </b> {Object.keys(changes).length} &nbsp;
                                <button className="btn btn-primary infraplan_button" onClick={submitChanges}><i
                                    className="fa fa-upload"></i> Click to submit all changes
                                </button>
                            </p>

                            <button className={"btn btn-info"} onClick={() => autoAssignPipesToBreaks(false)}>Auto assign pipes to all
                                breaks
                            </button>
                            &nbsp;
                            <button className={"btn btn-warning"} onClick={()=>autoAssignPipesToBreaks(true)}>Auto assign pipes to unassigned
                                breaks
                            </button>

                            <BreakTable
                                breaks={breaks}
                                handleChange={(breakId, pipeId) => {
                                    setChanges(prev => {
                                        let changes = Object.assign({}, prev);
                                        changes[breakId] = pipeId;
                                        return changes;
                                    })
                                    console.log(`Pipe ${pipeId} was assigned to Break ${breakId}`);
                                }}
                                changes={changes}
                            />
                        </>
                        : <p><b>Good news!</b> This project doesn't have any conflicting assignment from pipe to break!
                        </p>
                    : ""

            }
        </>
    );
}