import React, { useState, useCallback, useEffect } from 'react';
import Modal from '../Modal';

export default function MLCalibration(props) {
  const projectId = Api.getProjectId()

  const [hasExecutions, setHasExecutions] = useState(-1)
  const [isTriggeringRun, setIsTriggeringRun] = useState(false)
  const [validationPeriod, setValidationPeriod] = useState(2)
  const [useCache, setUseCache] = useState(false)
  const [isOpen, setIsOpen] = useState(false)

  const { disabled, pipelineType } = props

  const runPipeline = useCallback(() => {
    setIsTriggeringRun(true)
    const endpoint = pipelineType == "missing_values" ? `projects/${projectId}/ml/missing_values_pipeline` : `projects/${projectId}/ml/pipeline`
    const params = pipelineType == "missing_values" ? {} : {"use_cache": useCache, "validation_period": validationPeriod}
    Api.post(endpoint, params)
      .then(x => {
        setIsTriggeringRun(false)
        setHasExecutions(1)
        setIsOpen(false)
        toastr.success("Pipeline queued successfully!")
      })
      .catch(x => {
        setIsTriggeringRun(false)
        toastr.error("Could not queue pipeline!")
      })
  }, [useCache, validationPeriod])

  useEffect(() => {
    Api.get(`projects/${projectId}/ml/executions`, pipelineType == "missing_values" ? { pipeline_type: "missing_values" } : {})
      .then(x => {
        // TODO: this obviously needs to be done some other way as soon as we tweak comms
        if (x.message && x.message === "No executions found.") {
          setHasExecutions(0)
        } else {
          setHasExecutions(1)
        }
      })
      .catch(x => {
        setHasExecutions(0)
      })
  }, [])

  if (hasExecutions === -1) {
    return <div className="alert alert-info">Checking current executions of Machine Learning pipeline...</div>
  } else if (hasExecutions === 0) {
    return <>
      <Modal
        isOpen={ isOpen }
        onRequestClose={ () => setIsOpen(false) }
      >
        <>
        <h4>Run Pipeline</h4>
        <hr />
        { pipelineType != "missing_values" && 
        <form>
          <div className="form-group">
            <label htmlFor="validation_period">Validation Period Length (years)</label>
            <input
                value={ validationPeriod }
                onChange={ (e) => setValidationPeriod(e.currentTarget.value) }
                id="validation_period"
                type="number"
                className="form-control"
            />
          </div>
          <div className="form-check">
            <input
                value={ useCache }
                onChange={ (e) => setUseCache(!useCache) }
                id="use_cache"
                type="checkbox"
                className="form-check-input"
            />
            <label htmlFor="use_cache" className="form-check-label">Use Cached Data</label>
          </div>
        </form>}
        <div disabled={isTriggeringRun | disabled} onClick={runPipeline} className="btn btn-sm btn-info float-right">Run Machine Learning</div>
        </>
      </Modal>
      <div disabled={isTriggeringRun | disabled} onClick={() => setIsOpen(true)} className="btn btn-md btn-info infraplan_button">
        {pipelineType == "missing_values" && "Run Missing Values pipeline for this project."}
        {pipelineType != "missing_values" && "Run Machine Learning pipeline for this project."}
      </div>
    </>
  } else {
    return <div className="alert alert-warning">Machine Learning pipeline is running for this project...</div>
  }

}
