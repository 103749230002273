import React from 'react'
import {
  map, max, each, identity
} from 'lodash'

import { Group } from '@vx/group'
import { AxisLeft, AxisRight, AxisBottom } from '@vx/axis'
import { scaleLinear } from '@vx/scale'
import { AreaStack, LinePath, Line } from '@vx/shape'
import { ParentSize } from '@vx/responsive'
import { curveLinear, curveMonotoneX } from '@vx/curve'

import memoize from 'lru-memoize'

import {
  WithTooltip, Tooltip,
  Line as TooltipLine,
  Sensor as TooltipSensor,
  Content as TooltipContent
} from '../Tooltip'

import {
  tickLabelLeft,
  tickLabelRight,
  tickLabelBottom,
  numTicksFromHeight,
  gray,
  minmax,
  stackedMinmax,
  axisLabelStyle,
  getColorsMap
} from '../misc'

const getStackedDatum = memoize()((series) => {
  const length = max(map(series, (s) => s.data.length))
  const result = []

  for (let i = 0; i < length; i += 1) {
    const point = {}

    each(series, (s) => {
      point.x = point.x || s.data[i].x
      point[s.id] = s.data[i].y
    })

    result.push(point)
  }

  return result
})

const getIds = memoize()((series) => map(series, 'id'))

const Lines = ({
  series, label, xScale, yMax, numYTicks, colors, showLines
}) => {
  if (!series) return null

  const x = (d) => xScale(d.x)

  const maxValue = minmax(series, 'y')[1] * 1.2
  const yLineScale = scaleLinear({
    domain: [0, maxValue],
    rangeRound: [yMax, 0]
  })

  const yLine = (d) => yLineScale(d.y)

  return (
    <>
      <AxisLeft
        label={ label }
        scale={ yLineScale }
        numTicks={ numYTicks }
        tickLabelProps={ tickLabelLeft }
        stroke={ gray }
        hideTicks
        labelProps={ axisLabelStyle }
        labelOffset={ 50 }
      />
      <AxisLeft scale={ yLineScale }>{ (props) => (
        <Group
          left={ props.axisFromPoint.x - 50 }
          top={
            Math.round((props.axisFromPoint.y + props.axisToPoint.y) / 2)
              - (series.length * 30 / 2)
          }
        >{
          showLines ? map(series, (line, index) => (
            <Line
              key={ line.id || line.label }
              stroke={ colors[line.id] }
              strokeDasharray={ line.dasharray }
              strokeWidth={ 2 }
              from={{ x: 0, y: index * 40 }}
              to={{ x: 0, y: index * 40 + 20 }}
            />
          )) : null
        }</Group>
      )}</AxisLeft>
      { map(series, (line) => (
        <LinePath
          key={ line.id || line.label }
          data={ line.data }
          x={ x }
          y={ yLine }
          curve={ curveLinear }
          stroke={ colors[line.id] }
          strokeDasharray={ line.dasharray }
          strokeWidth={ 2 }
        />
      )) }
    </>
  )
}

const Areas = ({
  series, label, xMax, xScale, yMax, numYTicks, colors
}) => {
  if (!series) return null

  const datum = getStackedDatum(series)
  const ids = getIds(series)

  const yScale = scaleLinear({
    domain: [0, stackedMinmax(series, 'y')[1] * 1.2],
    range: [yMax, 0]
  })

  const x = (d) => xScale(d.data.x)
  const y0 = (d) => yScale(d[0])
  const y1 = (d) => yScale(d[1])

  return (
    <>
      <AxisRight
        label={ label }
        left={ xMax }
        scale={ yScale }
        tickLabelProps={ tickLabelRight }
        numTicks={ numYTicks }
        stroke={ gray }
        hideTicks
        labelProps={ axisLabelStyle }
        labelOffset={ 45 }
      />

      <AreaStack
        keys={ ids }
        data={ datum }
        curve={ curveMonotoneX }
        x={ x }
        y0={ y0 }
        y1={ y1 }
      >{ (area) => {
        const { stacks, path } = area

        return map(stacks, (stack) => {
          return (
            <path
              key={ `stack-${stack.key}` }
              d={ path(stack) }
              stroke="transparent"
              fill={ colors[stack.key] }
            />
          )
        })
      }}</AreaStack>
    </>
  )
}

class StackedAreaAndLines extends React.PureComponent {
  svgRef = React.createRef()

  static defaultProps = {
    margin: {
      left: 80,
      right: 80,
      top: 10,
      bottom: 100
    },
    showVerticalAxes: true,
    showPoints: true,
    showLinesLegendsLines: true
  }

  render() {
    const {
      forwardedRef,
      margin,
      areas,
      lines,
      parentWidth: width,
      parentHeight: height,
      showVerticalAxes,
      showPoints,
      bottomLabel,
      renderTooltipValue,
      linesLabel,
      areasLabel,
      renderTooltip,
      showLinesLegendsLines
    } = this.props

    if (!width || !height) return null

    const series = [...areas, ...lines]
    const colors = getColorsMap(series)

    const xMax = width - margin.left - margin.right
    const yMax = height - margin.top - margin.bottom

    const xScale = scaleLinear({
      domain: minmax(series, 'x'),
      range: [0, xMax]
    })

    const numYTicks = numTicksFromHeight(yMax)

    const svgRef = forwardedRef || this.svgRef

    return (
      <WithTooltip
        series={ series }
        xScale={ xScale }
        margin={ margin }
        getSvg={ () => svgRef.current }
        parentWidth={ width }
        parentHeight={ height }
      >{ (tooltipProps) => (
        <>
          <svg
            width={ width }
            height={ height }
            ref={ svgRef }
          >
            <defs>
              <pattern id="new-pipes-gradient"
                       width="4" height="4"
                       patternUnits="userSpaceOnUse" patternTransform={"rotate(45)"}>
                <rect width="2" height="4" transform="translate(0,0)" fill="darkblue"></rect>
              </pattern>
            </defs>
            <AxisBottom
              top={ height - margin.bottom }
              left={ margin.left }
              scale={ xScale }
              tickLabelProps={ tickLabelBottom }
              tickFormat={ identity }
              stroke={ gray }
              hideTicks
              label={ bottomLabel }
              labelProps={ axisLabelStyle }
              labelOffset={ 20 }
            />

            <Group top={ margin.top } left={ margin.left }>
              <Areas
                xScale={ xScale }
                xMax={ xMax }
                yMax={ yMax }
                series={ areas }
                colors={ colors }
                numYTicks={ numYTicks }
                showAxis={ showVerticalAxes }
                label={ areasLabel }
              />

              <Lines
                xScale={ xScale }
                yMax={ yMax }
                series={ lines }
                colors={ colors }
                numYTicks={ numYTicks }
                showAxis={ showVerticalAxes }
                showPoints={ showPoints }
                showLines={ showLinesLegendsLines }
                label={ linesLabel }
              />

              <TooltipLine { ...tooltipProps } yMax={ yMax } />
              <TooltipSensor { ...tooltipProps } xMax={ xMax } yMax={ yMax } />
            </Group>
          </svg>
          { renderTooltip && renderTooltip({
            ...tooltipProps, colors, renderValue: renderTooltipValue
          }) }
          { !renderTooltip && (
            <Tooltip
              { ...tooltipProps }
              margin={ margin }
              xMax={ xMax }
              yMax={ yMax }
            >
              <TooltipContent
                { ...tooltipProps }
                colors={ colors }
                renderValue={ renderTooltipValue }
              />
            </Tooltip>
          ) }
        </>
      ) }</WithTooltip>
    )
  }
}

export default React.forwardRef((props, ref) => (
  <ParentSize>
    {({ width, height }) => (
      <StackedAreaAndLines
        { ...props }
        forwardedRef={ ref }
        parentWidth={ width }
        parentHeight={ height }
      />
    )}
  </ParentSize>
))
