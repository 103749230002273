import React, { useState, useCallback, useEffect } from 'react'
import AgingCurve from './AgingCurve'
import { map } from 'lodash'
import MLStatus from '../MLStatus'

export default function MLAgingCurves(props) {

  const [prediction, setPrediction] = useState({})
  const [summary, setSummary] = useState({})
  const [cohorts, setCohorts] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [resultsExist, setResultsExist] = useState(false)

  const projectId = Api.getProjectId()

  useEffect(() => {
    Api.get(`projects/${projectId}/ml/meta`)
      .then(x => {
        setResultsExist(true)
      })
      .catch(error => {
        console.log(error)
        if (!error.notFound) {
          setResultsExist(true)  
        }
      })
  }, [])

  useEffect(() => {
    setIsLoading(true)
    Api.get(`projects/${projectId}/cohorts`)
      .then(x => {
        setCohorts(x)
        setIsLoading(false)
      })
      .catch(error => {
        setIsLoading(false)
      })
  }, [])

  const onCohortChange = useCallback((e) => {
    setIsLoading(true)
    // TODO: second part to go away with Ruby
    Promise.all([Api.get(`projects/${projectId}/ml/aging_curve`, { cohort: e.currentTarget.value }),  $.get(`/projects/${projectId}/cohorts/${e.currentTarget.value}/stats`, {}, null, 'json')])
      .then(x => {
        setIsLoading(false)
        setPrediction(x[0])
        setSummary(x[1])
      })
      .catch(error => {
        toastr.error("Could not fetch aging data!")
        console.error(error)
        setPrediction({})
        setIsLoading(false)
      })
  }, [])

  return (
    <>
      <MLStatus disabled={isLoading} />
      <div style={ { marginTop: "10px" } }>
        { resultsExist && <select
          onChange={ onCohortChange }
          disabled={ isLoading }
          className="custom-select prediction-title-select"
        >
          <option default disabled value="">Select a cohort</option>
          <option default value="">(no cohort)</option>
            { map(cohorts, (cohort) => (
              <option
                key={ cohort.id }
                value={ cohort.id }
              >
                { cohort.name }
              </option>
            )) }
          </select> }
          { isLoading && <div>Loading...</div> }
          { !isLoading && !resultsExist && <p>No machine learning pipeline runs were found for this project. Please run the pipeline and wait for results.</p>}
          { !isLoading && resultsExist && !prediction.break_rates && <div>Choose cohort above to load curves.</div>}
          { !isLoading && prediction.break_rates && <AgingCurve
            prediction={ prediction }
            summary={ summary }
          />}
      </div>
    </>
  )
}
