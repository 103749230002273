import React from 'react'
import Pipe from './Pipe'
import {getAssignedPipe} from "./helpers";

export default ({brk, handleChange, newPipe}) => {

    const selectedPipeId = getAssignedPipe(brk, newPipe);

    return (
        <tr>
            <td>
                <b>Sys. ID:</b> {brk.id} <br/>
                <b>ID:</b> {brk.breakRef} <br/>
                <b>Date of break:</b> {brk.brokeOn?.substr(0,10)} <br/>
                {brk.breakMaterialRef && <><b>Material:</b> {brk.breakMaterialRef} <br/></>}
                {brk.breakDiameter && <><b>Diameter:</b> {brk.breakDiameter} <br/></>}
            </td>
            <td>
                <table style={{fontSize: 'small'}}>
                    <thead>
                    <tr>
                        <th>&nbsp;</th>
                        <th>Sys. ID</th>
                        <th>ID</th>
                        <th>Date of Installation</th>
                        <th>Year of acquisition</th>
                        <th>Material</th>
                        <th>Length</th>
                        <th>Diameter</th>
                        <th>Life status</th>
                        <th>Date of Abandonment</th>
                        <th>Location</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td style={{width: '1px', verticalAlign: 'middle'}}>
                            <input type="radio" name={`breaks[${brk.id}]`} checked={selectedPipeId === null}/>
                        </td>
                        <td colSpan="10">No selection</td>
                    </tr>
                    {brk.pipes.map(pipe => (
                        <Pipe key={pipe.id} pipe={pipe} brkId={brk.id} handleChange={handleChange}
                              checked={selectedPipeId === pipe.id}/>
                    ))}
                    </tbody>
                </table>
            </td>
        </tr>
    );
}